// extracted by mini-css-extract-plugin
export var container = "HeroParagraph-module--container--b3b42";
export var containerWithCenterImage = "HeroParagraph-module--containerWithCenterImage--CbWrA";
export var containerWithImage = "HeroParagraph-module--containerWithImage--CMyZo";
export var content = "HeroParagraph-module--content--FDf-w";
export var contentIsCenterIt = "HeroParagraph-module--contentIsCenterIt--RF1Pz";
export var headline = "HeroParagraph-module--headline--ZVxyN";
export var headlineCenterIt = "HeroParagraph-module--headlineCenterIt--SS4a5";
export var image = "HeroParagraph-module--image--VTK3S";
export var imageContainerIsCentereit = "HeroParagraph-module--imageContainerIsCentereit--WEwn8";
export var imageIsOnAbsolutePositionToTheRight = "HeroParagraph-module--imageIsOnAbsolutePositionToTheRight--nP-bK";
export var isAbsoluteToTopRightContent = "HeroParagraph-module--isAbsoluteToTopRightContent--2GUw2";
export var isFlexingtoTheRight = "HeroParagraph-module--isFlexingtoTheRight--s4JB5";
export var makeColumnDesktop = "HeroParagraph-module--makeColumnDesktop--b9g7J";
export var marginRight = "HeroParagraph-module--marginRight--aqNIW";
export var textCenter = "HeroParagraph-module--textCenter--1HdD5";