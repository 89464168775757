// extracted by mini-css-extract-plugin
export var arrowDownIconContainer = "assessment-center-module--arrowDownIconContainer--Y9DuK";
export var arrowDownIconUnique = "assessment-center-module--arrowDownIconUnique--TiEWk";
export var arrowIconDown = "assessment-center-module--arrowIconDown--Dbn5l";
export var arrowIconDownOne = "assessment-center-module--arrowIconDownOne--q2ZxT";
export var arrowStylesDown = "assessment-center-module--arrowStylesDown--BEmo+";
export var arrowStylesUp = "assessment-center-module--arrowStylesUp--RwZsW";
export var buttonContainer = "assessment-center-module--buttonContainer--KfROD";
export var centereItParagraph = "assessment-center-module--centereItParagraph--GsZES";
export var content = "assessment-center-module--content--dy0Oe";
export var directionalParagraphContainer = "assessment-center-module--directionalParagraphContainer--ZwG+C";
export var displayFlex = "assessment-center-module--displayFlex--SXN--";
export var firstIconDesktop = "assessment-center-module--firstIconDesktop--E8Vvn";
export var megafonoFlexDesktop = "assessment-center-module--megafonoFlexDesktop--08NAD";
export var megafonoIcon = "assessment-center-module--megafonoIcon--knzqB";
export var onlyOnDesktop = "assessment-center-module--onlyOnDesktop--Ae4bE";
export var onlyOnMobile = "assessment-center-module--onlyOnMobile --s0n+8";
export var paragraph = "assessment-center-module--paragraph--+dnUE";
export var paragraphBold = "assessment-center-module--paragraphBold--GKZvm";
export var paragraphContainer = "assessment-center-module--paragraphContainer--HiKp1";
export var paragraphOnlyDesktop = "assessment-center-module--paragraphOnlyDesktop--EvGmO";
export var textAndImageSection = "assessment-center-module--textAndImageSection--ufEar";