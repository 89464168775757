import React from "react";
import { Link } from "gatsby";
import { classNames } from "../../helpers/classes";

//@ts-ignore
import * as styles from "./Button.module.css";

interface IButton {
  layout: "button" | "link";
  to: string;
  label: string;
}

const Button = ({ layout, to, label }: IButton) => {
  return (
    <Link
      to={to}
      className={classNames({
        [styles.button]: layout === "button",
        [styles.link]: layout === "link",
      })}
    >
      {label}
    </Link>
  );
};

export default Button;
