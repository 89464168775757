import * as React from "react";
import Layout from "../components/Layout/Layout";
import HeroParagraph from "../_paragraph/HeroParagraph/HeroParagraph";
import Icon from "../components/Icon/Icon";
import Button from "../components/Button/Button";

//@ts-ignore
import * as styles from "./assessment-center.module.css";

const StrategieHrChangeManagment = () => {
  return (
    <Layout>
      <HeroParagraph
        icon="bag"
        layout="hasBackgroundImage"
        imagePostion="isCenterIt"
        headline="Assessment Center + Development center"
        src={"images/connectionComputers"}
        alt=""
        makeColumnDesktop={false}
      />

      <div
        className={`
                    ${styles.content}
                    grid-8--mobile 
                    grid-8--tablet
                    grid-12--desktop-small
                `}
      >
        {/* Text and Image Section */}
        <section
          className={`
                        grid-8--mobile
                        grid-8--tablet
                        ${styles.textAndImageSection}
                    `}
        >
          <p
            className={`grid-4--mobile grid-4--tablet grid-6--desktop-small ${styles.paragraph}`}
          >
            Per quanto siano ricche le opportunità di riflessione e di
            apprendimento messe a disposizione da un piano di sviluppo
            manageriale, solo le risorse ed i loro capi diretti possono fare la
            differenza e determinarne il successo; i primi con il loro impegno
            protratto nel tempo, i secondi con il loro investimento nella
            crescita del proprio team.
          </p>
          <div className="grid-4--mobile grid-4--tablet grid-6--desktop-small">
            <Icon icon="cakeDown" />
          </div>

          {/* Arrow Icon Down */}
          <div className={`${styles.onlyOnMobile} ${styles.arrowIconDownOne}`}>
            <Icon icon="arrowDownRotate" />
          </div>
        </section>

        <p className={`grid-8--mobile grid-7--tablet ${styles.paragraph}`}>
          i primi con il loro impegno protratto nel tempo, i secondi con il loro
          investimento nella crescita del proprio team.
        </p>

        {/* Paragraph Container */}
        <div
          className={`grid-8--mobile grid-8--tablet grid-12--desktop-small ${styles.paragraphContainer}`}
        >
          <div className="grid-8--desktop-small grid-8--tablet grid-12--desktop">
            <p>
              Per questa ragione i metodi e gli strumenti che proponiamo sono
              pensati soprattutto per sollecitare e mantenere vivi la
              motivazione e l’impegno dei partecipanti e dei loro capi nello
              sviluppo delle competenze e delle abilità che compongono il
              Modello Aziendale di Riferimento.
            </p>
            <p>
              Un risultato che intendiamo costruire a partire da una
              reportistica, progettata proprio per essere utile alle persone che
              interagiscono quotidianamente con i valutati, per interpretarne
              correttamente potenzialità e carenze.
            </p>
          </div>

          <div
            className={`grid-4--desktop-small ${styles.arrowDownIconContainer} ${styles.onlyOnDesktop} ${styles.firstIconDesktop}`}
          >
            <Icon icon="arrowDownRotate" />
          </div>
        </div>

        {/* CenterIt paragraph */}
        <div className={`${styles.onlyOnMobile} ${styles.arrowIconDown}`}>
          <Icon icon="arrowIconDown" />
        </div>

        <div className={styles.paragraphOnlyDesktop}>
          <p
            className={`grid-8--mobile grid-8--tablet ${styles.centereItParagraph}`}
          >
            Puntualmente nella short list delle competenze da sviluppare
            figurano la Capacità di Cambiamento, l’Orientamento al Futuro, la
            Propensione ad Innovare.…e – altrettanto puntualmente - gli
            strumenti di valutazione e le esperienze proposte in aula
            testimoniano la scarsa convinzione con cui l’Organizzazione coltiva
            tali abilità. Negli ultimi anni sono stati informatizzati
            questionari ed esercitazioni «in basket», nella convinzione che
            sostituire un fascicolo di fogli di carta con lo schermo di un PC
            bastasse di per sé a garantire il giusto digital taste…
          </p>
        </div>
        <div
          className={`${styles.onlyOnDesktop} ${styles.arrowDownIconUnique} ${styles.arrowIconDown}`}
        >
          <Icon icon="arrowIconDown" />
        </div>

        <div className={` ${styles.displayFlex} ${styles.megafonoFlexDesktop}`}>
          {/* Megafono Icon */}
          <div className={`grid-4--desktop-small ${styles.megafonoIcon}`}>
            <Icon icon="megafono" />
          </div>

          <p
            className={`grid-8--mobile grid-8--tablet grid-8--desktop-small ${styles.paragraphBold}`}
          >
            Ma purtroppo ciò che contraddistingue l’era digitale non è l’uso dei
            device elettronici e delle tecnologie multimediali: è – piuttosto –
            la capacità di affrontare con interesse e flessibilità cambiamenti
            radicali ed inaspettati, di aprirsi a nuove esperienze, di accettare
            la sfida di rivedere le proprie capacità, le proprie competenze, le
            proprie strategie di relazione con gli altri.
          </p>
        </div>

        {/* Directional Paragraph Container */}
        <div
          className={`grid-8--mobile grid-8--tablet ${styles.directionalParagraphContainer}`}
        >
          <div>
            <div className={styles.arrowStylesUp}>
              <Icon icon="arrowStylesUp" />
            </div>
            <p className="grid-5--desktop-small">
              A nostro modo di vedere è proprio dalla scelta delle tecniche e
              degli strumenti di rilevazione delle capacità e delle competenze
              che l’Azienda conferisce credibilità al proprio Modello di
              Leadership: mettere in campo un approccio lontano dalla tradizione
              è il primo passo per manifestare un interesse veramente convinto
              per capacità e motivazioni orientate al cambiamento ed
              all'innovazione.
            </p>
            <p className="grid-5--desktop-small">
              Senz’altro è molto più semplice convincere i partecipanti che
              l’Azienda vuole davvero valorizzare lo spirito critico e la
              propensione innovativa delle proprie risorse, dopo aver condiviso
              un’esperienza di lavoro in squadra, dinamica e coinvolgente,
              imperniata su tematiche nuove ed interessanti, come la narrazione
              seriale, i new media e lo storytelling.
            </p>
            <div className={styles.arrowStylesDown}>
              <Icon icon="arrowStylesDown" />
            </div>
          </div>

          {/* Button Container */}
          <div className={styles.buttonContainer}>
            {/* Button */}
            <Button layout="button" to="#contatti" label="Scopri di più" />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default StrategieHrChangeManagment;
