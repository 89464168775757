import React from "react";
import { Icons } from "../../_typescript/type";
import Headline from "../../components/Headline/Headline";
import { classNames } from "../../helpers/classes";
import Icon from "../../components/Icon/Icon";

//@ts-ignore
import * as styles from "./HeroParagraph.module.css";

interface IHero {
  layout: "hasBackgroundImage";
  imagePostion: "isCenterIt" | "IsAbsoluteToTopRight" | "isFlexingOnTheRight";
  headline: string;
  icon: Icons;
  makeColumnDesktop: boolean;
}

const HeroParagraph = ({
  layout,
  headline,
  imagePostion,
  icon,
  makeColumnDesktop,
}: IHero) => {
  return (
    <div
      className={classNames({
        [styles.container]: true,
        [styles.containerWithCenterImage]: "isCenterIt" === imagePostion,
        [styles.containerWithImage]: "hasBackgroundImage" === layout,
      })}
    >
      {/* Content */}

      {!makeColumnDesktop && (
        <div
          className={classNames({
            [styles.content]: true,
            "grid-8--mobile": true,
            "grid-8--tablet": true,
            "grid-12--desktop-small": true,
            [styles.contentIsCenterIt]: "isCenterIt" === imagePostion,
            [styles.isFlexingtoTheRight]:
              "isFlexingOnTheRight" === imagePostion,
            [styles.isAbsoluteToTopRightContent]:
              "IsAbsoluteToTopRight" === imagePostion,
          })}
        >
          {/* Image Container */}
          <div
            className={classNames({
              [styles.imageContainer]: true,
              [styles.imageContainerIsCentereit]: "isCenterIt" === imagePostion,
              //Image is on Absolute position to the right
              [styles.imageIsOnAbsolutePositionToTheRight]:
                "IsAbsoluteToTopRight" === imagePostion,
            })}
          >
            <Icon icon={icon} />
          </div>

          {/* Headline */}
          <Headline
            className={classNames({
              "grid-3-mobile grid-4--tablet":
                "IsAbsoluteToTopRight" === imagePostion,
              [styles.headline]: true,
              [`grid-8--mobile ${styles.textCenter}`]:
                "isCenterIt" === imagePostion,
              [styles.marginRight]:
                "IsAbsoluteToTopRight" === imagePostion ||
                "isFlexingOnTheRight" === imagePostion,
            })}
            tagName="h1"
            headline={headline}
          />
        </div>
      )}
      {makeColumnDesktop && (
        <div
          className={classNames({
            [styles.content]: true,
            "grid-8--mobile": true,
            "grid-8--tablet": true,
            "grid-12--desktop-small": true,
            [styles.contentIsCenterIt]: "isCenterIt" === imagePostion,
            [styles.isFlexingtoTheRight]:
              "isFlexingOnTheRight" === imagePostion,
            [styles.isAbsoluteToTopRightContent]:
              "IsAbsoluteToTopRight" === imagePostion,
            [styles.makeColumnDesktop]: true,
          })}
        >
          {/* Image Container */}
          <div
            className={classNames({
              [styles.imageContainer]: true,
              [styles.imageContainerIsCentereit]: "isCenterIt" === imagePostion,
              //Image is on Absolute position to the right
              [styles.imageIsOnAbsolutePositionToTheRight]:
                "IsAbsoluteToTopRight" === imagePostion,
            })}
          >
            <Icon icon={icon} />
          </div>

          {/* Headline */}
          <Headline
            className={classNames({
              "grid-3-mobile grid-4--tablet":
                "IsAbsoluteToTopRight" === imagePostion,
              [styles.headline]: true,
              [`grid-8--mobile ${styles.textCenter}`]:
                "isCenterIt" === imagePostion,
              [styles.marginRight]:
                "IsAbsoluteToTopRight" === imagePostion ||
                "isFlexingOnTheRight" === imagePostion,
            })}
            tagName="h1"
            headline={headline}
          />
        </div>
      )}
    </div>
  );
};

export default HeroParagraph;
